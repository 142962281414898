// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChangePasswordForm-module__PasswordCard__kVlM9 ul{padding-inline-start:.5rem;list-style:none}.ChangePasswordForm-module__PasswordCard__kVlM9 li{text-align:left;margin-bottom:.25rem}.ChangePasswordForm-module__PasswordCard__kVlM9 .ChangePasswordForm-module__specialCharacters__TJfPQ{display:block}.ChangePasswordForm-module__passwordGroup__InQ3w{position:relative;display:flex;flex-wrap:wrap;align-items:stretch;width:100%}", "",{"version":3,"sources":["webpack://./src/changepassword/ChangePasswordForm.module.scss"],"names":[],"mappings":"AAIE,mDACE,0BAAA,CACA,eAAA,CAEF,mDACE,eAAA,CACA,oBAAA,CAEF,qGACE,aAAA,CAIJ,iDACE,iBAAA,CACA,YAAA,CACA,cAAA,CACA,mBAAA,CACA,UAAA","sourcesContent":["@import '~styles/variables';\n@import '~styles/sizes';\n\n.PasswordCard {\n  ul {\n    padding-inline-start: 0.5rem;\n    list-style: none;\n  }\n  li {\n    text-align: left;\n    margin-bottom: 0.25rem;\n  }\n  .specialCharacters {\n    display: block;\n  }\n}\n\n.passwordGroup {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: stretch;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PasswordCard": "ChangePasswordForm-module__PasswordCard__kVlM9",
	"specialCharacters": "ChangePasswordForm-module__specialCharacters__TJfPQ",
	"passwordGroup": "ChangePasswordForm-module__passwordGroup__InQ3w"
};
export default ___CSS_LOADER_EXPORT___;
