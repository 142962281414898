import * as React from 'react';
import { Component } from 'react';
import { v4 as uuid } from 'uuid';
import '../common/common.scss';
import { getCaptchaToken } from '../common/captcha';
import styles from './LoginForm.module.scss';
import passwordStyles from '../common/Password.module.scss';
import PrimaryButton from '../common/PrimaryButton';
import Logo from '../../public/logo.svg';
import HidePasswordSvg from '../../public/hide-password.svg';
import ShowPasswordSvg from '../../public/show-password.svg';

const errorMessages: { [key: string]: string } = {
  invalid_email_format: 'Invalid email',
  unknown_email: 'Invalid email',
  unknown_error: 'An unknown error has occurred',
};

class Login extends Component<any, any> {
  private orgInput: any | null;

  private emailInput: any | null;

  private passwordInput: any | null;

  constructor(props: any) {
    super(props);
    const defaultOrg = ((document.getElementById('_defOrg') as HTMLInputElement) || {}).value || '';
    this.state = {
      email: '',
      screen: 'email',
      orgId: defaultOrg,
      showOrg: !!defaultOrg,
      loading: !!defaultOrg,
      password: '',
      trace: undefined,
      showPassword: false,
      csrf: ((document.getElementById('_csrf') as HTMLInputElement) || {}).value,
    };

    // TODO -- use controlled components here
    // Reference for uncontrolled input used in emailForm
    this.emailInput = React.createRef();
    this.orgInput = React.createRef();
    this.passwordInput = React.createRef();
  }

  // Discover only after mount so we can call setState inside discover and only trigger it once.
  // If done in the constructor this may be triggered on components that never mount
  componentDidMount() {
    if (this.state.orgId) {
      this.discover();
    }
  }

  getScreenForm(screen: string) {
    /* eslint-disable default-case */
    switch (screen) {
      // case 'password': return this.passwordForm();
      case 'forgot_password':
        return this.resetPasswordForm();
      case 'providers':
        return this.providersList();
      case 'email':
        return this.emailForm();
      case 'password':
        return this.emailForm();
      case 'orgId':
        return this.emailForm();
    }
    /* eslint-enable default-case */
  }

  /**
   * This is where we would check if the user is configured to use SAML,
   * but for now we will always just show the password
   */
  async discover(provider = '', followRedirect = true) {
    // Optimization to reduce discovery calls
    const domain = this.state.email.split('@')[1];
    const discoverByOrgId = this.state.orgId && this.state.showOrg;
    if (
      provider
      || (discoverByOrgId && this.state.orgId !== this.state.discoveredDomain)
      || (!discoverByOrgId && domain && domain !== this.state.discoveredDomain)
    ) {
      this.setState({
        loading: true,
        // eslint-disable-next-line react/no-access-state-in-setstate
        discoveredDomain: discoverByOrgId ? this.state.orgId : domain,
        redirect: '',
      });
      const body = {
        orgId: this.state.orgId,
        email: this.state.email,
        _csrf: this.state.csrf,
        _captcha: await getCaptchaToken('discover'),
        provider,
      };
      const discoverResp = await fetch('/discover', {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        redirect: 'manual',
        body: JSON.stringify(body),
      })
        .then((r) => r.json())
        .catch((e) => {
          /* eslint-disable-next-line no-console */
          console.warn(e);
          return {};
        });
      if (!discoverResp || discoverResp.error) {
        if (this.state.screen === 'forgot_password') {
          return this.showScreen('forgot_password', { providers: [] });
        }

        if (this.state.showOrg) {
          return this.showScreen('orgId', {
            errorMessage: errorMessages[(discoverResp || {}).error] || 'An unknown error has occurred',
            providers: [],
          });
        }

        if (this.state.email) {
          return this.showScreen('password', { providers: [] });
        }

        return this.showScreen('email', { providers: [] });
      }
      if (discoverResp.redirect) {
        if (followRedirect) {
          window.location = discoverResp.redirect;
          return true;
        }
        // No password allowed for saml Users, even if their password manager filled it in
        if (this.passwordInput.current) {
          this.passwordInput.current.value = '';
        }
        this.setState({ redirect: discoverResp.redirect, password: '' });
      }
      if (this.state.screen === 'forgot_password') {
        return this.showScreen('forgot_password', { providers: discoverResp.providers || [] });
      }

      if (this.state.email) {
        return this.showScreen('password', { providers: discoverResp.providers || [] });
      }

      return this.showScreen('email', { providers: discoverResp.providers || [] });
    }
  }

  private verifyEmailErrors() {
    if (!this.state.email) {
      return { emailError: 'Email is required' };
    } if (!/[^@]+@[^@]+/.test(this.state.email)) {
      return { emailError: 'Invalid email format' };
    }
    return null;
  }

  private showForgotPasswordWithoutErrors() {
    // If the user has been unsuccessfully attempting login from the login form, then,
    // click "forgot password" we don't want leftover errors or email entry so clear
    this.setState({
      email: '', emailError: '', passwordError: '', errorMessage: '',
    });
    this.showScreen('forgot_password');
  }

  private toggleShowPassword() {
    this.setState((prevState: any) => ({ showPassword: !prevState.showPassword }));
  }

  async checkEmail() {
    const emailErrors = this.verifyEmailErrors();
    if (emailErrors) {
      this.showScreen('email', emailErrors, false);
    } else {
      this.setState({ emailError: '' });
      if (!this.state.showOrg || !this.state.orgId) {
        return this.discover();
      }
      return this.showScreen('password');
    }
  }

  async sendResetEmail() {
    // We don't want to POST and redirect if missing or invalid email
    const emailErrors = this.verifyEmailErrors();
    if (emailErrors) {
      this.showScreen('forgot_password', emailErrors);
      return;
    }
    // If here we can assume email validity so clear the corresponding state
    // so we don't continue display any previous invalid hints
    this.setState({ emailError: '' });
    const forgotResp = await fetch('/forgotPassword', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'manual',
      body: JSON.stringify({
        email: this.state.email,
        orgId: this.state.orgId,
        _csrf: this.state.csrf,
        _captcha: await getCaptchaToken('forgotPassword'),
      }),
    })
      .then((r) => r.json())
      .catch((e) => {
        /* eslint-disable-next-line no-console */
        console.warn(e);
        return {};
      });

    const ifAccountMatchesMessage = "If an account matches username or email you entered, you'll receive an email to reset your password shortly. If not please contact support.";
    if (forgotResp.success) {
      return this.showScreen('email', { message: ifAccountMatchesMessage, email: '' });
    }
    return this.showScreen('forgot_password', { emailError: 'An unknown error has occurred' });
  }

  async doLogin() {
    if (this.state.redirect) {
      // eslint-disable-next-line no-return-assign
      return (window.location = this.state.redirect);
    }
    // With a password manager, it is possible to populate the email field without
    // triggering discovery
    if (!this.state.discoveredDomain) {
      // If after discovery we need to redirect, don't bother submitting a password
      const redirecting = await this.discover();
      if (redirecting) {
        return;
      }
    }
    if (!this.checkEmail()) {
      return false;
    }
    if (!this.state.password) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        error: true,
        passwordError: 'Password is required',
      });
    } else {
      const body = {
        email: this.state.email,
        password: this.state.password,
        _csrf: this.state.csrf,
        _captcha: await getCaptchaToken('login'),
      };
      
      const loginResp = await fetch('/login', {
        method: 'POST',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json' },
        redirect: 'manual',
        body: JSON.stringify(body),
      })
        .then((r) => {
          this.setState({
            trace: r.headers.get('x-ct-trace')
          })
          return r.json();
        })
        .catch((e) => {
          /* eslint-disable-next-line no-console */
          console.warn(e);
          return {};
        });
      if (loginResp.success) {
        window.location.href = loginResp.redirect;
      } else {
        // This has to be set before we re-render the screen or else
        // the password field will not render empty.
        if (this.passwordInput.current) {
          this.passwordInput.current.value = '';
        }
        let errMsg = this.state.showOrg
        ? 'Incorrect organization id, email, or password'
        : 'Incorrect email or password'
        
        switch (loginResp.reason) {
          case 'user_expired':
            errMsg = 'Account is expired, please contact support.'
            break
          case 'user_disabled':
            errMsg = 'Account is inactive, please contact support.'
            break
          case 'excessive_login_attempts':
            errMsg = 'Too many failed login attempts, please contact support.'
            break
          default:
            console.log(`unknown error reason: ${loginResp.reason}`)
            break
        }
        this.showScreen('password', {
          password: '',
          errorMessage: errMsg,
          emailError: '',
          passwordError: '',
        });
      }
    }
  }

  async showScreen(screen: string, extraState: any = {}, setFocus = true) {
    this.setState(
      {
        screen,
        // eslint-disable-next-line react/no-unused-state
        error: !!extraState.emailError || !!extraState.passwordError,
        loading: false,
        message: '',
        ...extraState,
      },
    );
    if (setFocus && screen === 'email' && this.emailInput.current) {
      this.emailInput.current.focus();
    } else if (setFocus && screen === 'password' && this.emailInput.current) {
      this.passwordInput.current.focus();
    } else if (setFocus && screen === 'orgId' && this.orgInput.current) {
      this.orgInput.current.focus();
    }
  }

  providersList() {
    if (this.state.providers && this.state.providers.length) {
      // Hack: TODO -- refactor this to order upstream and account for other cases. Use case is to
      // have the email login button come before the corporate sso button
      const orderedProviders: any[] = [];
      this.state.providers.forEach((p: any) => {
        if (p.type === 'ct') {
          orderedProviders[0] = p;
        } else {
          orderedProviders[1] = p;
        }
      });

      return orderedProviders
        .map((p: any, i: number) => {
          const b = (
            <PrimaryButton
              additionalCss={i === 0 ? 'mb-2' : 'mt-2'}
              copy={p.name}
              key={`btn-${uuid()}`}
              onClickHandler={() => (p.type === 'ct' ? this.doLogin.bind(this)() : this.discover.bind(this)(p.name))}
            />
          );
          return i > 0 ? [<div key={`or-${uuid()}`}>or</div>, b] : [b];
        })
        .flat();
    }
    return <PrimaryButton copy="Log in" onClickHandler={this.doLogin.bind(this)} />;
  }

  orgField() {
    if (this.state.showOrg) {
      return (
        <>
          <label className="visually-hidden" htmlFor="orgId">
            Organization ID
          </label>
          <input
            ref={this.orgInput}
            type="input"
            id="orgId"
            className={`input-base ${styles.organization}`}
            placeholder="Organization ID"
            value={this.state.orgId}
            disabled={!!this.state.loading}
            autoComplete="orgId"
            onChange={(e) => {
              // When a password manager updates the fields, the input is not active.
              // Do discovery if the input was set this way
              const doDisco = document.activeElement !== this.orgInput.current;
              this.setState({ orgId: e.target.value }, () => {
                if (doDisco) {
                  this.discover('', false);
                }
              });
            }}
            onKeyPress={(e) => {
              if (this.state.screen !== 'forgot_password' && e.key === 'Enter') {
                this.doLogin();
              }
            }}
            onBlur={() => {
              if (this.state.orgId) this.discover();
            }}
          />
        </>
      );
    }
    return (
      <>
        <label className="visually-hidden" htmlFor="orgId">
          Organization
        </label>
        <input
          type="text"
          id="orgId"
          name="orgId"
          tabIndex={-1}
          className="visually-hidden"
          spellCheck="false"
          autoComplete="off"
          value={this.state.orgId}
          readOnly
        />
      </>
    );
  }

  emailForm() {
    return (
      <div className="d-flex flex-column align-items-center">
        {this.orgField()}
        <label className="visually-hidden" htmlFor="email">
          Email
        </label>
        <input
          ref={this.emailInput}
          type="email"
          id="email"
          name="email"
          autoComplete="email"
          className={`input-base ${this.state.emailError ? 'is-invalid-input' : ''}`}
          placeholder="Email…"
          disabled={!!this.state.loading}
          onChange={(e) => {
            // When a password manager updates the fields, the input is not active.
            // Do discovery if the input was set this way
            let doDisco = false;
            if (document.activeElement !== this.emailInput.current && !this.state.showOrg) {
              doDisco = true;
            }
            this.setState({ email: e.target.value }, () => {
              if (doDisco) {
                this.discover('', false);
              }
            });
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.doLogin();
            }
          }}
          onBlur={() => {
            this.discover();
          }}
          value={this.state.email}
        />
        {!!this.state.emailError && (
          <span role="status" aria-live="polite" className="is-invalid-text">
            {this.state.emailError}
          </span>
        )}
        <p className={`input-group ${passwordStyles.passwordGroup}`}>
          <label className="visually-hidden" htmlFor="password">
            Password
          </label>
          <input
            ref={this.passwordInput}
            type={this.state.showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            className={`form-control ${passwordStyles.passwordBase} ${this.state.passwordError ? 'is-invalid-input' : ''}`}
            placeholder="Password…"
            disabled={!!this.state.loading || !!this.state.redirect}
            autoComplete="current-password"
            onChange={(e) => this.setState({ password: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.doLogin();
              }
            }}
          />
          <span
            className={`input-group-text ${passwordStyles.inputGroupTextOverride}`}
            role="button"
            title={this.state.showPassword ? 'Hide password' : 'Reveal password'}
            id="passBtn"
            aria-label="toggle password visibility"
            onClick={() => this.toggleShowPassword()}
            onMouseDown={(e) => e.preventDefault()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.toggleShowPassword();
              }
            }}
            tabIndex={0}
          >
            {this.state.showPassword ? (
              <img src={HidePasswordSvg} alt="Hide password from clear site" className={passwordStyles.showPassIcon} />
            ) : (
              <img src={ShowPasswordSvg} alt="Reveal password in cleartext" className={passwordStyles.showPassIcon} />
            )}
          </span>
        </p>
        {!!this.state.passwordError && (
          <span role="status" aria-live="polite" className="is-invalid-text">
            {this.state.passwordError}
          </span>
        )}
        {this.providersList()}
        <button type="button" key="reset" className={`btn btn-link ${styles.forgot} p-0`} onClick={() => this.showForgotPasswordWithoutErrors()}>
          Forgot password?
        </button>
        {this.state.showOrg ? (
          ''
        ) : (
          <button type="button" key="showorg" className={`btn btn-link ${styles.showorg} p-0`} onClick={() => this.setState({ showOrg: true })}>
            Organization login
          </button>
        )}
      </div>
    );
  }

  resetPasswordForm() {
    return (
      <div className="d-flex flex-column align-items-center">
        {this.orgField()}
        <label className="visually-hidden" htmlFor="resetEmail">
          Email
        </label>
        <input
          ref={this.emailInput}
          type="text"
          id="resetEmail"
          placeholder="Email…"
          className={`input-base ${this.state.emailError ? 'is-invalid-input' : ''}`}
          value={this.state.email}
          onChange={(e) => this.setState({ email: e.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.sendResetEmail();
            }
          }}
        />
        {!!this.state.emailError && (
          <span role="status" aria-live="polite" className="is-invalid-text">
            {this.state.emailError}
          </span>
        )}
        <PrimaryButton copy="Email reset link" onClickHandler={this.sendResetEmail.bind(this)} />
        <button type="button" className={`btn btn-link ${styles.returnlogin} p-0`} onClick={() => this.showScreen('email')}>
          Return to login
        </button>
        {this.state.showOrg ? (
          ''
        ) : (
          <button type="button" key="showorg" className={`btn btn-link ${styles.showorg} p-0`} onClick={() => this.setState({ showOrg: true })}>
            Organization login
          </button>
        )}
      </div>
    );
  }

  render() {
    return (
      <section className="card">
        <div className="card-body">
          <button type="button" className="btn btn-link d-flex justify-content-center w-100 p-0" onClick={() => this.showScreen('email')} aria-labelledby="ciphertrace">
            <img src={Logo} className="logo" id="ciphertrace" alt="CipherTrace" />
          </button>
          {this.state.errorMessage && (
            <>
              <span className={`error-text ${styles.errorText}`}>{this.state.errorMessage}</span>
              {this.state.trace && (
                <div>
                  <span className={`error-text ${styles.errorText}`}>Trace: {this.state.trace}</span>
                </div>
              )}
            </>
          )}
          {this.state.message && <span className={`message ${styles.message}`}>{this.state.message}</span>}
          <form>{this.getScreenForm(this.state.screen)}</form>
          <a href="mailto:support@ciphertrace.com?subject=Login support" className="help">
            Need support?
          </a>
          <span className="terms">
            By logging into CipherTrace, you agree to our
            {' '}
            <a className="termsLink" href="https://ciphertrace.com/terms/">
              Terms
            </a>
            .
          </span>
        </div>
      </section>
    );
  }
}

export default Login;
