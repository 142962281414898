import * as React from 'react';
import { Component } from 'react';
import '../common/common.scss';
import styles from './ConsentForm.module.scss';
import PrimaryButton from '../common/PrimaryButton';
import Logo from '../../public/logo.svg';
import { getCaptchaToken } from '../common/captcha';

const scopeTextMap = new Map([
  ['openid', 'View and manage your openId'],
  ['email', 'View and manage your email'],
  ['profile', 'View and manage your profile'],
  ['offline_access', 'View and manage your offline access'],
  // ['ct' , "View and manage your Ciphertrace account"],
]);

class ConsentForm extends Component<any, any> {
  private form: HTMLFormElement | null;

  constructor(props: any) {
    super(props);
    this.form = null;
    this.state = {
      // @ts-ignore
      csrf: (document.getElementById('_csrf') || {}).value,
      // @ts-ignore
      scopes: (document.getElementById('_scopes') || { value: '' }).value.split(','),
      // @ts-ignore
      clientName: (document.getElementById('_client') || {}).value,
    };
  }

  async submit(accept: Boolean = false) {
    if (this.form != null) {
      this.form.accept.value = accept;
      this.form._captcha.value = await getCaptchaToken('consent');
      this.form.submit();
    }
  }

  scopeAccessList() {
    const scopes = this.state.scopes.filter((s: string) => scopeTextMap.has(s));
    return (
      <ul>
        {scopes.map((scope: string, i: any) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <li key={i}>{scopeTextMap.get(scope)}</li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <div className={`card ${styles.ConsentCard}`}>
        <div className="card-body">
          <img src={Logo} alt="" className={`logo ${styles.logo}`} />
          <div className="w-100">
            <b>{this.state.clientName}</b>
            {' '}
            would like to access your CipherTrace account, which would allow:
          </div>
          {/* TODO: not all scopes are supported */}
          {this.scopeAccessList()}
          {/* eslint-disable-next-line no-return-assign */}
          <form ref={(f) => (this.form = f)} method="post">
            <input type="hidden" name="_captcha" value="" />
            <input type="hidden" name="_csrf" value={this.state.csrf} />
            <input type="hidden" name="accept" value="" />
            <div className="w-100">
              <PrimaryButton copy="Accept" onClickHandler={() => this.submit.bind(this)(true)} />
              <button type="button" key="reset" className={`btn btn-link ${styles.cancel} p-0`} onClick={() => this.submit(false)} aria-label="cancel">
                Cancel
              </button>
            </div>
          </form>
          <a href="mailto:support@ciphertrace.com?subject=Log in support" className="help">
            Need support?
          </a>
          <span className="terms">
            By logging into CipherTrace, you agree to our
            {' '}
            <a className="termsLink" href="https://ciphertrace.com/terms/">
              Terms
            </a>
            .
          </span>
        </div>
      </div>
    );
  }
}

export default ConsentForm;
