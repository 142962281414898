// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConsentForm-module__ConsentCard__Wo3it ul{margin-inline-start:-1em}.ConsentForm-module__ConsentCard__Wo3it li{text-align:left;font-size:small}.ConsentForm-module__ConsentCard__Wo3it .ConsentForm-module__cancel__Jg3Bw{margin-bottom:1rem}.ConsentForm-module__ConsentCard__Wo3it .ConsentForm-module__logo__indVS{margin-bottom:1rem}", "",{"version":3,"sources":["webpack://./src/consent/ConsentForm.module.scss"],"names":[],"mappings":"AAGE,2CACE,wBAAA,CAEF,2CACE,eAAA,CACA,eAAA,CAEF,2EACE,kBAAA,CAEF,yEACE,kBAAA","sourcesContent":["@import '~styles/sizes';\n\n.ConsentCard {\n  ul {\n    margin-inline-start: -1em;\n  }\n  li {\n    text-align: left;\n    font-size: small;\n  }\n  .cancel {\n    margin-bottom: map-get($spacers, 3);\n  }\n  .logo {\n    margin-bottom: map-get($spacers, 3);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConsentCard": "ConsentForm-module__ConsentCard__Wo3it",
	"cancel": "ConsentForm-module__cancel__Jg3Bw",
	"logo": "ConsentForm-module__logo__indVS"
};
export default ___CSS_LOADER_EXPORT___;
