export async function getCaptchaToken(action: string) : Promise<string> {
  const captchaKey = (<HTMLInputElement> document.getElementById('_captchaKey') || {}).value;
  if (captchaKey) {
    return new Promise(((res) => {
      grecaptcha.ready(() => res(grecaptcha.execute(captchaKey, { action })));
      // Don't wait forever for recaptcha to be ready, fail out instead.
      setTimeout(res, 10000);
    }));
  }

  return '';
}
