import * as React from 'react';
import { Component } from 'react';
import '../common/common.scss';
import styles from './LogoutForm.module.scss';
import PrimaryButton from '../common/PrimaryButton';
import Logo from '../../public/logo.svg';

export class LogoutForm extends Component {
  constructor(props: any) {
    super(props);
    // @ts-ignore
    const loginUrl = (document.getElementById('_loginUrl') || {}).value;
    this.state = { loginUrl };
  }

  private redirect() {
    window.location.assign(this.state.loginUrl);
  }

  render() {
    return (
      <div className={`card ${styles.LogoutCard}`}>
        <div className="card-body">
          <img src={Logo} alt="" className="logo" />
          <div className="w-100">
            <h5>You are now logged out</h5>
            {this.state.loginUrl && <PrimaryButton copy="Login" onClickHandler={this.redirect.bind(this)} />}
          </div>
          <a href="mailto:support@ciphertrace.com?subject=Log in support" className="help">
            Need support?
          </a>
          <span className="terms">
            By logging into CipherTrace, you agree to our
            {' '}
            <a className="termsLink" href="https://ciphertrace.com/terms/">
              Terms
            </a>
            .
          </span>
        </div>
      </div>
    );
  }
}
