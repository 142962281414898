import * as React from 'react';
import { Component } from 'react';
import '../common/common.scss';
import styles from './ChangePasswordForm.module.scss';
import passwordStyles from '../common/Password.module.scss';
import PrimaryButton from '../common/PrimaryButton';
import Logo from '../../public/logo.svg';
import HidePasswordSvg from '../../public/hide-password.svg';
import ShowPasswordSvg from '../../public/show-password.svg';
import { getCaptchaToken } from '../common/captcha';

import { IChangePasswordBody } from 'ct-login-api/src/types'

// form view model
interface IChangePasswordFormState {
  //
  csrf?: string
  redirectUrl?: string
  // form field state
  password?: string
  // display state
  errorScreen?: boolean
  error?: boolean
  trace?: string
  errorMessage?: string
  showPassword?: boolean
  // validation
  minPasswordLength?: number
  requireUpper?: boolean
  requireLower?: boolean
  requireNumber?: boolean
  requireSpecial?: boolean
  updateSuccessful?: boolean
}

interface IChangePasswordProps {}

interface ChangePasswordResponse {
  body: any
  trace: string
}

class ChangePasswordForm extends Component<IChangePasswordProps, IChangePasswordFormState> {
  public state : IChangePasswordFormState;
  private _resetToken: string;

  constructor(props: any) {
    super(props);
    // @ts-ignore
    const serverErrorText = (document.getElementById('_error_description') || {}).value;
    // @ts-ignore
    this._resetToken = (document.getElementById('_resetToken') || {}).value;

    // @ts-ignore
    const passwordPolicy = JSON.parse((document.getElementById('_passwordPolicy') || {}).value || '{}') || {};
    if (serverErrorText) {
      this.state = {
        errorScreen: true,
        error: true,
        errorMessage: serverErrorText,
      };
    } else {
      this.state = {
        // @ts-ignore
        csrf: (document.getElementById('_csrf') || {}).value,
        showPassword: false,
        minPasswordLength: passwordPolicy.minLength || 8,
        /* eslint-disable max-len */
        requireUpper: passwordPolicy.requireUpper === undefined ? true : passwordPolicy.requireUpper,
        requireLower: passwordPolicy.requireLower === undefined ? true : passwordPolicy.requireLower,
        requireNumber: passwordPolicy.requireNumber === undefined ? true : passwordPolicy.requireNumber,
        requireSpecial: passwordPolicy.requireSpecial === undefined ? true : passwordPolicy.requireSpecial,
        /* eslint-enable max-len */
        updateSuccessful: false,
        redirectUrl: 'https://login.stage.cipheruse.com?login_hint=org_canonical_id',
      };
    }
  }

  async submit() {
    if (!this.state.password) {
      return this.setState({
        error: true,
        errorMessage: 'A new password is required',
      });
    }
    if (!this.validatePassword(this.state.password)) {
      return this.setState({
        error: true,
        errorMessage: 'Password does not meet policy requirements',
      });
    }

    const body : IChangePasswordBody = {
      password: this.state.password,
      token: this._resetToken,
      _csrf: this.state.csrf,
      _captcha: await getCaptchaToken('password/reset'),
    };

    const resp : ChangePasswordResponse = await fetch('/password/reset', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then((r) => {
        return {
          body: r.json(),
          trace: r.headers.get('x-ct-trace')
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        return {};
      });

    const respBody = await resp.body;
    if (respBody.success) {
      this.setState({
        updateSuccessful: true,
        redirectUrl: respBody.redirect || '',
      });
    } else {
      this.setState({
        error: true,
        trace: resp.trace,
        errorMessage: respBody.error || 'An unknown error has occurred',
      });
    }
  }

  private validatePassword(password: string): boolean {
    if (/^\s*$/.test(password)) {
      return false;
    }
    if (password.trim().length < (this.state.minPasswordLength || false)) {
      return false;
    }
    if (this.state.requireLower && !/[a-z]/.test(password)) {
      return false;
    }
    if (this.state.requireUpper && !/[A-Z]/.test(password)) {
      return false;
    }
    if (this.state.requireSpecial && !/[~!@#$%&*]/.test(password)) {
      return false;
    }

    return true;
  }

  private passwordForm() {
    return (
      <div className="w-100">
        <input id="email" type="email" name="email" tabIndex={-1} className="hidden" spellCheck="false" />
        <p className={`${this.state.error ? 'is-invalid-input' : ''} ${passwordStyles.passwordGroup} ${styles.passwordGroup}`}>
          <label className="visually-hidden" htmlFor="newPassword">
            New Password
          </label>
          <input
            id="newPassword"
            type={this.state.showPassword ? 'text' : 'password'}
            className={`form-control ${passwordStyles.passwordBase}`}
            color="primary"
            name="password"
            disabled={this.state.loading}
            ref={(input) => input && input.focus()}
            autoComplete="password"
            onChange={(e) => this.setState({ password: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.submit();
              }
            }}
          />
          <span
            className={`input-group-text ${passwordStyles.inputGroupTextOverride}`}
            role="button"
            title={this.state.showPassword ? 'Hide password' : 'Reveal password'}
            id="passBtn"
            aria-label="toggle password visibility"
            onClick={() => this.toggleShowPassword()}
            onMouseDown={(e) => e.preventDefault()}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.toggleShowPassword();
              }
            }}
            tabIndex={0}
          >
            {this.state.showPassword ? (
              <img src={HidePasswordSvg} alt="Hide password from clear site" className={passwordStyles.showPassIcon} />
            ) : (
              <img src={ShowPasswordSvg} alt="Show password in cleartext" className={passwordStyles.showPassIcon} />
            )}
          </span>
        </p>
        {!!this.state.error && (
          <>
          <span role="status" aria-live="polite" className="is-invalid-text">
            {this.state.errorMessage}
          </span>
            {this.state.trace ? (
            <span role="status" aria-live="polite" className="is-invalid-text">
              trace: {this.state.trace}
            </span>
            ) : ''}
          </>
        )}
        <p className="text-start ps-1 mt-2">Password requirements:</p>
        <ul>
          <li>
            {this.state.minPasswordLength}
            {' '}
            or more upper and lowercase characters
          </li>
          {this.state.requireNumber ? <li>At least one number</li> : ''}
          {this.state.requireSpecial ? (
            <li>
              At least of one of:
              {' '}
              {/* eslint-disable max-len */}
              <span className={styles.specialCharacters}>($&#8201;@&#8201;#&#8201;&&#8201;%&#8201;~&#8201;!&#8201;*)</span>
              {' '}
              must be in the password
            </li>
          ) : (
            ''
          )}
        </ul>
        <PrimaryButton copy="Update" onClickHandler={this.submit.bind(this)} />
      </div>
    );
  }

  private toggleShowPassword() {
    this.setState((prevState: IChangePasswordFormState) => ({ showPassword: !prevState.showPassword }));
  }

  private errorPage() {
    return <>
      <h5>{this.state.errorMessage}</h5>
      {this.state.trace && (
        <div>
          <span className={`error-text ${styles.errorText}`}>Trace: {this.state.trace}</span>
        </div>
      )}
    </>
  }

  private redirect() {
    if (this.state.redirectUrl) {
      window.location.assign(this.state.redirectUrl);
    }
  }

  private successPage() {
    return (
      <div className="w-100">
        <h5>Password updated successfully</h5>
        {this.state.redirectUrl && <PrimaryButton copy="Return to login" onClickHandler={this.redirect.bind(this)} />}
      </div>
    );
  }

  render() {
    return (
      <div className={`card ${styles.PasswordCard}`}>
        <div className="card-body">
          <img src={Logo} alt="" className="logo" />
          {/* eslint-disable-next-line no-nested-ternary */}
          {this.state.errorScreen
            ? this.errorPage()
            : this.state.updateSuccessful
              ? this.successPage()
              : this.passwordForm()}
          <a href="mailto:support@ciphertrace.com?subject=Log in support" className="help">
            Need support?
          </a>
          <span className="terms">
            By logging into CipherTrace, you agree to our
            {' '}
            <a className="termsLink" href="https://ciphertrace.com/terms/">
              Terms
            </a>
            .
          </span>
        </div>
      </div>
    );
  }
}

export default ChangePasswordForm;
