import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

// Ensure any URL parameters are removed to discourage bookmarking
// window.history.replaceState(undefined, document.title, "/");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
