// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[0].use[1]!../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[0].use[2]!./common.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Password-module__inputGroupTextOverride__IQl3t{position:absolute;right:0;bottom:0;top:0;background:transparent;border:transparent;z-index:5}.Password-module__showPassIcon___kXwu{width:1rem;color:#00080f;margin-right:.6875rem}.Password-module__passwordBase__I8UV1{margin-top:0;border-radius:.25rem !important;z-index:0}.Password-module__passwordGroup__xcDp7{margin-top:1.625rem;margin-bottom:0}", "",{"version":3,"sources":["webpack://./src/common/Password.module.scss"],"names":[],"mappings":"AAIA,gDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,KAAA,CACA,sBAAA,CACA,kBAAA,CAEA,SAAA,CAGF,sCACE,UAAA,CACA,aAAA,CACA,qBAAA,CAGF,sCAEE,YAAA,CACA,+BAAA,CACA,SAAA,CAIF,uCACE,mBAAA,CACA,eAAA","sourcesContent":["@import '~styles/variables';\n@import '~styles/sizes';\n\n// BS Overrides\n.inputGroupTextOverride {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  top: 0;\n  background: transparent;\n  border: transparent;\n  // BS increases .input-group > .form-control:focus to 3 :)\n  z-index: 5;\n}\n\n.showPassIcon {\n  width: map-get($spacers, 3);\n  color: $ct-secondary-dark;\n  margin-right: 0.6875rem;\n}\n\n.passwordBase {\n  composes: input-base from \"./common.scss\";\n  margin-top: 0;\n  border-radius: .25rem !important;\n  z-index: 0;\n}\n\n// Additively overrides input-group from BS\n.passwordGroup {\n  margin-top: map-get($spacers, 4);\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGroupTextOverride": "Password-module__inputGroupTextOverride__IQl3t",
	"showPassIcon": "Password-module__showPassIcon___kXwu",
	"passwordBase": "Password-module__passwordBase__I8UV1 " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["input-base"] + "",
	"passwordGroup": "Password-module__passwordGroup__xcDp7"
};
export default ___CSS_LOADER_EXPORT___;
