import * as React from 'react';
import styles from './PrimaryButton.module.scss';

export interface PrimaryButtonProps {
  additionalCss: string;
  copy: string;
  onClickHandler: () => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = function PrimaryButton({
  additionalCss = '',
  copy,
  onClickHandler,
}) {
  return (
    <button
      className={`${styles.btn} ${additionalCss} btn btn-primary btn-large text-uppercase`}
      type="button"
      onClick={() => onClickHandler()}
    >
      {copy}
    </button>
  );
};

export default PrimaryButton;
