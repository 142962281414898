import * as React from 'react';
import './App.scss';
import Login from './login/LoginForm';
import ConsentForm from './consent/ConsentForm';
import ErrorView from './error/ErrorView';
import ChangePasswordForm from './changepassword/ChangePasswordForm';
import { LogoutForm } from './logout/LogoutForm';

const App: React.FC = function App(): JSX.Element {
  // Basic hack form of app routing
  if (window.location.pathname.indexOf('consent') === 1) {
    return (
      <main className="App">
        <ConsentForm />
      </main>
    );
  }
  if (window.location.pathname.indexOf('password/reset') === 1) {
    return (
      <main className="App">
        <ChangePasswordForm />
      </main>
    );
  }
  if (window.location.pathname.indexOf('logout/success') === 1) {
    return (
      <main className="App">
        <LogoutForm />
      </main>
    );
  }
  if (window.location.pathname.indexOf('error') === 1) {
    return (
      <main className="App" role="main">
        <ErrorView />
      </main>
    );
  }

  return (
    <main className="App" role="main">
      <Login />
    </main>
  );
};

export default App;
