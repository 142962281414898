// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LogoutForm-module__LogoutCard__wHCMT h5{padding:1rem 0 .75rem}", "",{"version":3,"sources":["webpack://./src/logout/LogoutForm.module.scss"],"names":[],"mappings":"AAEE,yCACE,qBAAA","sourcesContent":["@import '~styles/sizes';\n.LogoutCard {\n  h5 {\n    padding: map-get($spacers, 3) 0 0.75rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LogoutCard": "LogoutForm-module__LogoutCard__wHCMT"
};
export default ___CSS_LOADER_EXPORT___;
