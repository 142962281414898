// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginForm-module__errorText__IwsBh,.LoginForm-module__message__b0IVl{bottom:-0.375rem;position:relative}.LoginForm-module__returnlogin__tnq6d,.LoginForm-module__showorg__G7Yck,.LoginForm-module__forgot__ZUyRF{margin-bottom:1rem;text-transform:unset;font-size:.8125rem !important}", "",{"version":3,"sources":["webpack://./src/login/LoginForm.module.scss"],"names":[],"mappings":"AAGA,sEAEE,gBAAA,CACA,iBAAA,CAGF,yGAGE,kBAAA,CAEA,oBAAA,CAEA,6BAAA","sourcesContent":["@import '~styles/variables';\n@import '~styles/sizes';\n\n.errorText,\n.message {\n  bottom: -0.375rem;\n  position: relative;\n}\n\n.returnlogin,\n.showorg,\n.forgot {\n  margin-bottom: map-get($spacers, 3);\n  // .btn above does uppercase but button links should be sentance case\n  text-transform: unset;\n  // We need to beat .btn.btn-link upstream\n  font-size: 0.8125rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorText": "LoginForm-module__errorText__IwsBh",
	"message": "LoginForm-module__message__b0IVl",
	"returnlogin": "LoginForm-module__returnlogin__tnq6d",
	"showorg": "LoginForm-module__showorg__G7Yck",
	"forgot": "LoginForm-module__forgot__ZUyRF"
};
export default ___CSS_LOADER_EXPORT___;
